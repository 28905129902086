<template>
    <ul v-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'">
                    <h2>{{ item.title }}</h2>
                </router-link>
            </li>

            <li v-else :class="className + '__li'" :key="item.title">
                <div :class="className + '__link gnb__link--toggle d-xl-none'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link :to="item.path" :class="className + '__link gnb__link--toggle d-none d-xl-flex'">
                    <h2>{{ item.title }}</h2>
                </router-link>
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
    },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "커뮤니티",
                    path: "/community",
                },
                {
                    title: "리커버",
                    path: "/recover",
                },
                {
                    title: "콘텐츠",
                    path: "/content",
                    children: [
                        {
                            title: "영상",
                            path: "/content/solution",
                        },
                        {
                            title: "칼럼",
                            path: "/content/column",
                        },
                        {
                            title: "설문조사",
                            path: "/content/survey",
                        },
                        {
                            title: "테스트",
                            path: "/content/test?type=test",
                        },
                    ],
                },
                // {
                //     title: "테스트",
                //     path: "/addiction/test?type=test",
                //     children: [
                //     {
                //             title: "5대 중독",
                //             path: "/addiction/test?type=test",
                //         },
                //         {
                //             title: "하루 중독",
                //             path: "/addiction/test?type=day",
                //         },
                //     ],
                // },
                // {
                //     title: "콘텐츠",
                //     path: "/content",
                //     children: [
                //         {
                //             title: "콘텐츠 픽",
                //             path: "/content",
                //         },
                //         {
                //             title: "플레이 픽",
                //             path: "/content/interactive",
                //         },
                //     ],
                // },
                // {
                //     title: "지식정보",
                //     path: "/information/column",
                //     children: [
                //         {
                //             title: "칼럼",
                //             path: "/information/column",
                //         },
                //         {
                //             title: "시사토픽",
                //             path: "/information/issue",
                //         },
                //         {
                //             title: "묻고 답하기",
                //             path: "/information/qna",
                //         },
                //         {
                //             title: "자주 묻는 궁금증",
                //             path: "/information/faq",
                //         },
                //     ],
                // },
                // {
                //     title: "커뮤니티",
                //     path: "/community",
                //     children: [
                //         {
                //             title: "자유게시판",
                //             path: "/community/board?code=free&tab=3",
                //         },
                //         {
                //             title: "인증게시판",
                //             path: "/community/board?code=certification&tab=2",
                //         },
                //         {
                //             title: "챌린지게시판",
                //             path: "/community/board?code=challenge&tab=4",
                //         },
                //     ],
                // },
            ],
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {},
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
};
</script>
