<template>
    <div class="d-flex justify-center align-center">
        <i class="icon icon-recommend mr-4 mr-lg-8"></i>
        <p class="page-text--sm grey-9--text">
            {{ ( likeCount - dislikeCount )?.format() }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        likeCount: { type: Number },
        dislikeCount: { type: Number }
    },
    data() {
        return {};
    },
};
</script>
