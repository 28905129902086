var render = function render(){
  var _vm$board, _vm$board2, _vm$board3, _vm$board4, _vm$board5, _vm$board6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-item"
  }, [_c('v-card', {
    staticClass: "mb-16",
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "thumb"
  }, [_c('div', {
    staticClass: "thumb__inner",
    style: `background-image: url(${(_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.thumb})`
  }), _vm.hot ? _c('div', {
    staticClass: "icon-hot"
  }, [_vm._v("HOT")]) : _vm._e()])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('v-chip', {
    staticClass: "mr-4 mr-lg-8",
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.category.name) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count', {
    attrs: {
      "viewCount": (_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.viewCount,
      "commentCount": (_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.commentCount
    }
  })], 1)], 1), _c('p', {
    staticClass: "tit tit--sm font-weight-medium ellip mt-8 mt-lg-16"
  }, [_vm._v(_vm._s((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.subject))]), _vm.tag ? _c('div', {
    staticClass: "px-xl-8 mt-2 mt-xl-8"
  }, [_c('board-tag', {
    attrs: {
      "hashTags": (_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6.hashTags
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }