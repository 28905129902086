<template>
    <div>
        <v-row class="ma-n4 ma-lg-n8">
            <v-col cols="auto" class="pa-4 pa-lg-8">
                <board-count-view :viewCount="viewCount" />
            </v-col>
            <v-col v-if="recommend" cols="auto" class="pa-4 pa-lg-8">
                <board-count-recommend :likeCount="likeCount" :dislikeCount="dislikeCount" />
            </v-col>
            <v-col v-if="!survey" cols="auto" class="pa-4 pa-lg-8">
                <board-count-comment :commentCount="commentCount" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardCountRecommend from "@/components/client/board/board-count--recommend.vue";
import BoardCountComment from "@/components/client/board/board-count--comment.vue";

export default {
    components: {
        BoardCountView,
        BoardCountRecommend,
        BoardCountComment,
    },
    props: {
        viewCount: { type: Number },
        commentCount: { type: Number },
        likeCount: { type: Number },
        dislikeCount: { type: Number },
        recommend: { type: Boolean },
        survey: { type: Boolean },
        test: { type: Boolean }
    },
    data() {
        return {};
    },
};
</script>
