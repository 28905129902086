import test from "./test";
import result from "./result";
import checkup from "./checkup";
import categories from "./categories"

export default {
    categories,
    test,
    result,
    checkup,
};
