var render = function render(){
  var _this$recover, _vm$recover, _vm$recover$thumb, _vm$recover2, _vm$recover3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "to": '/recover-view/' + ((_this$recover = this.recover) === null || _this$recover === void 0 ? void 0 : _this$recover._id),
      "rounded": "",
      "color": "grey-f7"
    }
  }, [_c('div', {
    staticClass: "px-20 px-md-30 py-30 py-lg-56"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center text-center"
  }, [_c('v-avatar', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.lgAndUp ? 124 : 90
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": (_vm$recover = _vm.recover) === null || _vm$recover === void 0 ? void 0 : (_vm$recover$thumb = _vm$recover.thumb) === null || _vm$recover$thumb === void 0 ? void 0 : _vm$recover$thumb.src,
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-12 mt-lg-24"
  }, [_c('h3', {
    staticClass: "tit font-weight-medium primary--text"
  }, [_vm._v(_vm._s((_vm$recover2 = _vm.recover) === null || _vm$recover2 === void 0 ? void 0 : _vm$recover2.name))]), _c('p', {
    staticClass: "tit tit--sm mt-4 mt-lg-8"
  }, [_vm._v(_vm._s((_vm$recover3 = _vm.recover) === null || _vm$recover3 === void 0 ? void 0 : _vm$recover3.job))])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }