<template>
    <section v-if="banners">
        <div class="banner-slide">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <!-- S: 배너 -->
                    <div v-for="banner in banners" :key="banner._id" class="swiper-slide">
                        <v-card tile :href="banner?.url" class="banner">
                            <div class="banner__inner" :style="`background-image: url(${banner?.image})`"></div>
                        </v-card>
                    </div>
                    <!-- E: 배너 -->
                </div>
            </div>
            <div class="banner-slide__control">
                <v-btn icon large class="banner-btn banner-btn--prev"><i class="icon icon-left-arrow"></i></v-btn>
                <v-btn icon large class="banner-btn banner-btn--next"><i class="icon icon-right-arrow"></i></v-btn>
            </div>
        </div>
    </section>
</template>

<script>
import api from "@/api"
import Swiper from "swiper/bundle";

export default {
    data() {
        return {
            banners: null
        }
    }, 
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banners } = await api.v1.banners.gets({ params: { levelCode: this.levelCode, code: "top" } });
            console.log(banners)
            this.banners = banners;

            this.$nextTick(() => {
                this.swiper = new Swiper(".banner-slide .swiper-container", {
                    spaceBetween: 20,
                    slidesPerView: 1,
                    speed: 500,
                    loop: true,
                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: ".banner-slide .banner-btn--next",
                        prevEl: ".banner-slide .banner-btn--prev",
                    },
                });
            });
        },
    },
    computed: {
        levelCode() {
            return this.$store.state.levelCode;
        },
    },
}
</script>

<style lang="scss" scoped>
.banner{
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc((200 / 1310) * 100%);
    &__inner{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.banner-slide{
    position: relative;
    .banner-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
        &--prev{
            left: 16px;
        }
        &--next{
            right: 16px;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
            background-color: rgba(0, 0, 0, .3);
        }
        &.v-btn.v-btn--icon.v-size--large{
            width: 40px;
            height: 40px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .banner-slide{
        position: relative;
        .banner-btn{
            &--prev{
                left: 40px;
            }
            &--next{
                right: 40px;
            }&.v-btn.v-btn--icon.v-size--large{
                width: 56px;
                height: 56px;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>