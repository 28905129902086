var render = function render(){
  var _ref;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('i', {
    staticClass: "icon icon-recommend mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s((_ref = _vm.likeCount - _vm.dislikeCount) === null || _ref === void 0 ? void 0 : _ref.format()) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }