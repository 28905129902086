import axios from "@/plugins/axios";

let url = "/api/v1/boards";

import comments from "./comments";
import bookmarks from "./bookmarks";
import categories from "./categories";
import users from "./users";

export default {
    comments,
    bookmarks,
    categories,
    users,
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    postThumb(data, thumb) {
        var formData = new FormData();
        formData.append("thumb", thumb);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/thumb`, formData, { headers }).then((result) => result.data);
    },
    like(data) {
        return axios.patch(`${url}/${data._id}/like`, data).then((result) => result.data);
    },
    disLike(data) {
        return axios.patch(`${url}/${data._id}/dislike`, data).then((result) => result.data);
    },
    // bookmark(data) {
    //     return axios.post(`${url}/${data._id}/bookmark`, data).then((result) => result.data);
    // }
};
