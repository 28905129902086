<template>
    <div v-if="buttomBanner?.image" class="mb-60 mb-lg-100">
        <v-container>
            <a :href="buttomBanner?.url" target="_blank">
                <v-img :src="buttomBanner?.image" :aspect-ratio="1600/230" class="w-100"></v-img>
            </a>

            <!-- 구글 애드센스 -->
            <!-- 리커버스 하단 광고 -->
            <!-- 임시로 display: none 해놓음. 사용시 display: block으로 변경. -->
            <ins class="adsbygoogle"
            style="display:none"
            data-ad-client="ca-pub-1978848462814059"
            data-ad-slot="5034405767"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </v-container>
    </div>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            buttomBanner: null,
        };
    },
    mounted() {
        this.init();

        (adsbygoogle = window.adsbygoogle || []).push({});
    },
    methods: {
        async init() {
            await this.getBanner();
        },
        async getBanner() {
            let { banner: buttomBanner } = await api.v1.banners.get({ code: "buttom", params: { levelCode: this.levelCode } });

            this.buttomBanner = buttomBanner;
        },
    },
    computed: {
        levelCode() {
            return this.$store.state.levelCode;
        },
    },
};
</script>

<style lang="scss" scoped></style>
