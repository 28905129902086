import addictions from "./addictions";
import surveys from "./surveys";
import auction from "./auction";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import fairs from "./fairs";
import forms from "./forms";
import me from "./me";
import message from "./message";
import setting from "./setting";
import shop from "./shop";
import terms from "./terms";
import users from "./users";
import popups from "./popups";

export default {
    addictions,
    surveys,
    auction,
    banners,
    boards,
    center,
    fairs,
    forms,
    me,
    message,
    setting,
    shop,
    terms,
    users,
    popups,
};
