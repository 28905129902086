<template>
    <v-card :to="'/recover-view/'+this.recover?._id" rounded color="grey-f7">
        <div class="px-20 px-md-30 py-30 py-lg-56">
            <div class="d-flex flex-column align-center text-center">
                <v-avatar :size="$vuetify.breakpoint.lgAndUp ? 124 : 90">
                    <v-img :src="recover?.thumb?.src" :aspect-ratio="1 / 1" class="w-100" />
                </v-avatar>
                <div class="mt-12 mt-lg-24">
                    <h3 class="tit font-weight-medium primary--text">{{ recover?.name }}</h3>
                    <p class="tit tit--sm mt-4 mt-lg-8">{{ recover?.job }}</p>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props : {
        shadow : {type :Boolean, default : false},
        recover: { type: Object, default: () => {} }
    },
    methods: {
        // move() {
        //     this.$router.push(`/recover-view/${this.recover?._id}`)
        // }
    },
}
</script>

<style lang="scss" scoped>
.thumb{
    padding-top: calc((368 / 250) * 100%);
}
</style>