var render = function render(){
  var _vm$sideBanner, _vm$sideBanner2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-banner"
  }, [_c('v-container', [_c('div', {
    staticClass: "side-banner__inner"
  }, [_c('div', {
    staticClass: "side-banner__left"
  }, [_vm._t("default")], 2), _vm.sideBanner ? _c('div', {
    staticClass: "side-banner__right"
  }, [_c('a', {
    attrs: {
      "href": (_vm$sideBanner = _vm.sideBanner) === null || _vm$sideBanner === void 0 ? void 0 : _vm$sideBanner.url,
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "contain": "",
      "src": (_vm$sideBanner2 = _vm.sideBanner) === null || _vm$sideBanner2 === void 0 ? void 0 : _vm$sideBanner2.image,
      "aspect-ratio": 250 / 700
    }
  })], 1), _c('ins', {
    staticClass: "adsbygoogle",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-ad-client": "ca-pub-1978848462814059",
      "data-ad-slot": "4701301642",
      "data-ad-format": "auto",
      "data-full-width-responsive": "true"
    }
  })]) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }