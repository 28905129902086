import axios from "@/plugins/axios";

let url = "/api/v1/boards";

export default {
    gets(_board, data){
        return axios.get(`${url}/${_board}/comments`, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${_board}/comments/${data._id}`).then(result => result.data);
    },
    post(_board, data){
        return axios.post(`${url}/${_board}/comments`, data).then(result => result.data);
    },
    put(_board, data){
        return axios.put(`${url}/${_board}/comments/${data._id}`, data).then(result => result.data);
    },
    delete(_board, data){
        return axios.delete(`${url}/${_board}/comments/${data._id}`).then(result => result.data);
    },
    like(_board, data) {
        return axios.patch(`${url}/${_board}/comments/${data._id}/like`, data).then((result) => result.data);
    },
    disLike(_board, data) {
        return axios.patch(`${url}/${_board}/comments/${data._id}/dislike`, data).then((result) => result.data);
    },
    bestCommnets(_board) {
        return axios.get(`${url}/${_board}/comments/best`).then((result) => result.data);
    },
}
