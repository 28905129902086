import axios from "@/plugins/axios";

let url = "/api/console/center/questions";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    postImage(data, image) {
        var formData = new FormData();
        formData.append("image", image);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/image`, formData, { headers });
    },
};
