import axios from "@/plugins/axios";
import categories from "./categories";
import result from "./result";

let url = "/api/v1/surveys";

export default {
    categories,
    result,
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`, data).then((result) => result.data);
    },
};
