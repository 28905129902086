var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banners ? _c('section', [_c('div', {
    staticClass: "banner-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.banners, function (banner) {
    return _c('div', {
      key: banner._id,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "banner",
      attrs: {
        "tile": "",
        "href": banner === null || banner === void 0 ? void 0 : banner.url
      }
    }, [_c('div', {
      staticClass: "banner__inner",
      style: `background-image: url(${banner === null || banner === void 0 ? void 0 : banner.image})`
    })])], 1);
  }), 0)]), _c('div', {
    staticClass: "banner-slide__control"
  }, [_c('v-btn', {
    staticClass: "banner-btn banner-btn--prev",
    attrs: {
      "icon": "",
      "large": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow"
  })]), _c('v-btn', {
    staticClass: "banner-btn banner-btn--next",
    attrs: {
      "icon": "",
      "large": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow"
  })])], 1)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }