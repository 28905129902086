<template>
    <v-row class="row--x-small">
        <v-col v-for="(hashTag, index) in hashTags?.split(', ')" :key="index" cols="auto">
            <p class="page-text primary--text">
                #<span>{{ hashTag }}</span>
            </p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        hashTags: { type: String },
    },
    data() {
        return {};
    },
};
</script>
