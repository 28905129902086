var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__top py-16"
  }, [_c('v-container', {
    staticClass: "container--sm"
  }, [_c('v-row', {
    staticClass: "page-text--sm grey-9--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/notice"
    }
  }, [_vm._v("공지사항")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/event"
    }
  }, [_vm._v("이벤트")])], 1), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/mypage/inquiry"
    }
  }, [_vm._v("문의사항")])], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/inquiry"
    }
  }, [_vm._v("문의사항")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/service"
    }
  }, [_vm._v("서비스 소개")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/terms"
    }
  }, [_vm._v("이용약관")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("개인정보처리방침")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "footer__link",
    attrs: {
      "to": "/report"
    }
  }, [_vm._v("신고가이드")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer__bottom py-40"
  }, [_c('v-container', {
    staticClass: "container--sm"
  }, [_c('v-row', {
    staticClass: "row--large",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-6 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ci.svg",
      "contain": "",
      "max-width": "164"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text--sm line-height-15 grey-9--text"
  }, [_vm._v(" 회사명 : 이겨내컴퍼니 주식회사"), _c('span', {
    staticClass: "text-center d-none d-md-inline-block",
    staticStyle: {
      "width": "40px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-md-none"
  }), _vm._v(" 대표자명 : 차유람"), _c('span', {
    staticClass: "text-center d-none d-md-inline-block",
    staticStyle: {
      "width": "40px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-md-none"
  }), _vm._v(" 대표번호 : 032-217-1267"), _c('span', {
    staticClass: "text-center d-none d-md-inline-block",
    staticStyle: {
      "width": "40px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-md-none"
  }), _vm._v(" 사업등록번호 : 446-88-01241 "), _c('br'), _vm._v(" 주소 : 인천광역시 부평구 부평대로 293, 9층 908호(청천동, 인천콘텐츠기업지원센터) ")])])], 1)], 1)], 1), _c('u-scroll-top')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }