var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "ma-n4 ma-lg-n8"
  }, [_c('v-col', {
    staticClass: "pa-4 pa-lg-8",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count-view', {
    attrs: {
      "viewCount": _vm.viewCount
    }
  })], 1), _vm.recommend ? _c('v-col', {
    staticClass: "pa-4 pa-lg-8",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count-recommend', {
    attrs: {
      "likeCount": _vm.likeCount,
      "dislikeCount": _vm.dislikeCount
    }
  })], 1) : _vm._e(), !_vm.survey ? _c('v-col', {
    staticClass: "pa-4 pa-lg-8",
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count-comment', {
    attrs: {
      "commentCount": _vm.commentCount
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }