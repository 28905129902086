module.exports = {

    // 공용
    common: {
        "SBA 국제유통센터": "SBA 국제유통센터",
        "국제유통센터": "국제유통센터",
        "로그인": "로그인",
        "로그아웃": "로그아웃",
        "글쓰기": "글쓰기",
        "마이페이지": "마이페이지",
        "페이지소개": "페이지소개",
        "매칭상담회": "매칭상담회",
        "자주 묻는 질문": "자주 묻는 질문",
        "공지사항": "공지사항",
        "기업정보": "기업정보",
        "상품관리": "상품관리",
        "1:1 문의": "1:1 문의",
        "개인정보처리방침": "개인정보처리방침",
        "셀러 회원 마이페이지": "셀러 회원 마이페이지",
        "바이어 회원 마이페이지": "바이어 회원 마이페이지",

        "자세히 보기": "자세히 보기",
        "관리자 문의": "관리자 문의",
        "아이디 저장": "아이디 저장",
        "아이디 찾기": "아이디 찾기",
        "비밀번호 찾기": "비밀번호 찾기",

        "제목": "제목",
        "작성자": "작성자",
        "작성일": "작성일",
        "조회수": "조회수",

        "매칭상담회 설명": "매칭상담회 설명",
        "참여제품": "참여제품",
        "참여기업": "참여기업",
        "참여바이어": "참여바이어",

        "전체": "전체",
        "신청하기": "신청하기",
        "신청중": "신청중",
        "진행중": "진행중",
        "참여중": "참여중",
        "종료": "종료",
        "신청": "신청",

        "select_all": ":: 전체 ::",
        "선정년도": "선정년도",
        "상품명": "상품명",
        "뒤로가기": "뒤로가기",
        "바로가기": "바로가기",
        "상세내용": "상세내용",
        "SMC_홍보마당": "SMC 홍보마당",
        "홈페이지": "홈페이지",
        "전성분": "전성분",
        "컬러": "컬러",
        "사이즈": "사이즈",
        "제조국": "제조국",
        "제조방법": "제조방법",
        "출시일자": "출시일자",
        "어워드_선정일": "어워드 선정일",
        "판매사이트": "판매사이트",
        "sales_price": "판매가(소비자가)",
        "주요특징": "주요특징",
        "prodcut_essential_information": "상품 필수 정보",
        "매칭신청": "매칭신청",
        "기업명": "기업명",
        "제품군": "제품군",
        "인증구분": "인증구분",
        "서울어워드": "서울어워드",
        "슈퍼루키": "슈퍼루키",
        "기타": "기타",
        "업체명": "업체명",
        "대표자이름": "대표자이름",
        "카테고리": "카테고리",
        "주소": "주소",
        "회사소개서": "회사소개서",
        "활동지역": "활동지역",
        "바이어유형": "바이어유형",
        "온라인": "온라인",
        "오프라인": "오프라인",
        "저장": "저장",
        "시간": "시간",
        "저장되었습니다": "저장되었습니다",
        "취소": "취소",
        "매칭거절": "매칭거절",

        "시도": "City/State",
        "시구군": "City/gu/gun",

        attachments: "첨부파일",
        list: "리스트",
        title: "제목",
        confirm: "확인",
        writing: "글쓰기",
        answer_complete: "답변완료",
        unanswered: "미답변",
        number: "번호",
        inquiry: "문의사항",
        answer_status: "답변상태",
        date_created: "작성일",
        search: "검색",
        required_fields: "필수 입력항목",

        셀러기업모집중: "셀러기업모집중",
        바이어신청중: "바이어신청중",
        companyClick: '※ 기업명을 클릭 시 세부 정보를 확인할 수 있습니다.'
    },

    // 마이페이지
    mypage: {
        "회원정보수정": "회원정보수정",
        support_center: "1:1문의",
        my_products: "상품관리",
        my_events: "매칭상담회",
        company_information: "기업정보",
        company_profile: "회사 소개서",
        company_profile_upload: ".pdf, .xlsx, .jpg, .png 50Mb이하 첨부가능 (PDF첨부를 권장합니다)",
        company_profile_warning: "※ 회사소개서의 경우 기업이 열람할 수 있습니다. 반드시 개인정보를 삭제 후 업로드 부탁드립니다.",
        CEO_name: "대표자 이름",
        company_edit_message1: "기업 정보 수정이 필요하신 경우 수정 후 아래의",
        save: "저장",
        company_edit_message2: "버튼을 꼭 눌러주세요.",
        buyer_activity_area: "바이어 활동지역",
    },

    // 메인페이지
    main: {
        platform: {
            tit: "플랫폼 이용 방법",
            subtit: "셀러 이용 메뉴얼",
            txt: "Seller's manual",
            subtit2: "바이어 이용 메뉴얼",
            txt2: "Buyer's manual",
        }
    },

    // 페이지소개
    about: {
        title: "매칭상담회 플랫폼",
        intro_txt: "서울어워드 상품과 SBA 국제유통센터에서 발굴한 바이어 간 매칭을 지원합니다.",
        intro_txt2: "해외로 수출할 만한 우수한 상품을 찾는 유통인들과 우수한 제품을 국내외 유통 및 수출할 수 있는 판로 개척이 필요한 중소기업을 위해 유통 채널이 입점된 B2B 매칭상담회 플랫폼입니다.",
        qna_tit: "계정 정보는 어떻게 확인할 수 있나요?",
        qna_txt: "중소 제조기업 분들에게는 서울어워드 신청시 기재한 메일로 계정 정보를 전달드렸습니다. 메일을 확인하셔서 로그인후 마이페이지에서 정보 수정을 부탁드리겠습니다.",
        qna_tit2: "매칭상담회 셀러로 참여하고 싶어요!",
        qna_txt2: "B2B 매칭상담회에는 SBA 국제유통센터에서 진행하는 ‘서울어워드’, ‘슈퍼루키’에 참여한 기업만 참여하실 수 있습니다.",
        qna_btn2: "서울어워드 신청 바로가기",
        qna_tit3: "매칭상담회 바이어로 참여하고 싶어요!",
        qna_txt3: "바이어로 참여를 희망하실 경우, 하단 링크를 통해 바이어 지원을 해주시면 검토 후 개별 연락을 드릴 예정입니다.",
        qna_btn3: "바이어 신청 바로가기",
    },

    // 자주묻는질문
    faq: {
        type: {
            이용문의: "이용문의",
            홈페이지문의: "홈페이지문의",
            개인정보문의: "개인정보문의",
            기타: "기타",
        }
    },
    schedule_dialog: {
        title: '매칭상담회 기간 중 참여 가능한 스케줄을 설정해주세요',
        sub_title: '추후 수정을 원하실 경우 [마이페이지-매칭상담회-매칭스케줄]에서 수정을 부탁드립니다',
        buyer: {
            'ex1': "매칭상담은 온라인 또는 오프라인으로 선택하여 진행 가능합니다.",
            'ex2': "희망하시는 매칭상담 방식을 선택해주세요.",
            'ex3': '※ 온라인 진행 시 Zoom 프로그램을 활용한 매칭상담 진행, 오프라인 진행 시 기업과 1:1 대면 매칭상담 진행 예정'
        },
        seller: {
            ex1: "아래 스케줄표는 바이어가 매칭이 가능한 스케줄입니다.",
            ex2: "기업에서 가능한 일정을 최대 3가지를 선택하여 매칭신청을 부탁드립니다.",
            ex3: "바이어가 최종적으로 시간을 확정하면 매칭이 완료됩니다.",
            ex4: "※ 매칭을 원하시지 않으시면 우측의 매칭거절을 클릭해주세요",
        },
        application_question: "매칭상담을 신청하시겠습니까?",
        application_complete: "매칭상담이 신청되었습니다",
        reject_question: "매칭상담을 거절하시겠습니까?",
        reject_compelete: "매칭상담이 거절되었습니다",
    }
};