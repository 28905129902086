import axios from "@/plugins/axios";

let url = "/api/v1/me/levelups";

export default {
    post(data) {
        console.log(data);
        return axios.post(url, data).then((result) => result.data);
    },
    file: {
        post(_levelup, file) {
            var formData = new FormData();
            formData.append("file", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return axios.post(`${url}/${_levelup}/file`, formData, { headers }).then((result) => result.data);
        },
        delete(_levelup, index) {
            return axios.delete(`${url}/${_levelup}/file`).then((result) => result.data);
        },
    },
};
