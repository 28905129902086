<template>
    <v-menu v-if="!noneActive" bottom offset-y>
        <template v-slot:activator="{ on }">
            <button v-on="on" :class="{ 'user-button--lg': large }" class="d-block">
                <v-row class="ma-n2" align="center" justify="center">
                    <v-col cols="auto" class="pa-2">

                        <!-- 일반회원아이콘 -->
                        <i v-if="user?.levelCode === 'normal'" class="icon icon-user-level1"></i>

                        <!-- 유경험자아이콘 -->
                        <i v-if="user?.levelCode === 'experience'" class="icon icon-user-level2"></i>

                        <!-- 전문가아이콘 -->
                        <i v-if="user?.levelCode === 'expert'" class="icon icon-user-level3"></i>

                    </v-col>
                    <v-col cols="auto" class="pa-2">
                        <p :class="textStyle">{{ user?.nickname }}</p>
                    </v-col>
                </v-row>
            </button>
        </template>
        <v-btn :to="`/posting/${user?._id}`" small color="white" class="id-btn h-lg-40px min-w-lg-120px">
            <span class="grey-1--text font-weight-regular">
                작성 글 보기
            </span>
        </v-btn>
    </v-menu>
    <v-row v-else class="ma-n2" align="center" justify="center" :class="{ 'user-button--lg': large }">
        <v-col cols="auto" class="pa-2">

            <!-- 일반회원아이콘 -->
            <i v-if="user?.levelCode === 'normal'" class="icon icon-user-level1"></i>

            <!-- 유경험자아이콘 -->
            <i v-if="user?.levelCode === '유경험자'" class="icon icon-user-level2"></i>

            <!-- 전문가아이콘 -->
            <i v-if="user?.levelCode === '전문가'" class="icon icon-user-level3"></i>

        </v-col>
        <v-col cols="auto" class="pa-2">
            <p :class="textStyle">{{ user?.nickname }}</p>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: {
        user: { type: Object, default: null },
        textStyle: { type: String, default: null },
        large: { type: Boolean, default: false },
        noneActive: { type: Boolean, default: false },
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.v-menu__content{
    background-color: transparent;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    transform: translate(20px, 2px);
}
</style>
