import { render, staticRenderFns } from "./main-footer-ad.vue?vue&type=template&id=57297b30&scoped=true&"
import script from "./main-footer-ad.vue?vue&type=script&lang=js&"
export * from "./main-footer-ad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57297b30",
  null
  
)

export default component.exports