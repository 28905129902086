import axios from "@/plugins/axios";

let url = "/api/v1/me";

import bids from "./bids";
import bookmarks from "./bookmarks";
import carts from "./carts";
import coupons from "./coupons";
import fairs from "./fairs";
import inquires from "./inquires";
import introductions from "./introductions";
import likes from "./likes";
import matchings from "./matchings";
import orders from "./orders";
import points from "./points";
import products from "./products";
import purchases from "./purchases";
import reviews from "./reviews";
import schedules from "./schedules";

import addiction from "./addiction";
import survey from "./survey";
import boards from "./boards";
import levelup from "./levelup";

export default {
    bids,
    bookmarks,
    carts,
    coupons,
    fairs,
    inquires,
    introductions,
    likes,
    matchings,
    orders,
    points,
    products,
    purchases,
    reviews,
    schedules,

    addiction,
    survey,
    boards,
    levelup,

    get() {
        return axios.get(url).then((result) => result.data);
    },
    put(data) {
        return axios.put(url, data).then((result) => result.data);
    },
    confirm(data) {
        return axios.get(`${url}/confirm`, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
};
