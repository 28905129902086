<template>
    <header class="header header--fixed" v-scroll="fixedScroll">
        <!-- S: body -->
        <div class="header__body">
            <v-container>
                <h1 class="header__logo">
                    <router-link to="/" title="홈으로 이동"><span class="d-sr-only">리커버스</span></router-link>
                </h1>
                <div class="header__contents">
                    <div class="header__right">
                        <user-menu></user-menu>
                        <!-- <header-search></header-search> -->
                    </div>
                    <div class="header__gnb justify-start">
                        <!-- S: Gnb -->
                        <client-gnb className="gnb"></client-gnb>
                        <!-- E: Gnb -->
                    </div>
                </div>
            </v-container>
        </div>
        <!-- E: body -->
        <!-- S: hamburger -->
        <div class="hamburger-btn">
            <span></span>
        </div>
        <!-- E: hamburger -->
    </header>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";
// import HeaderSearch from "@/components/client/inc/header-search.vue";
import UserMenu from "@/components/client/inc/user-menu.vue";

export default {
    components: {
        ClientGnb,
        // HeaderSearch,
        UserMenu,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작

        if (this.isDesktop) {
            this.initPcEvent();
        }
        if (this.isMobile) {
            this.initMobileEvent();
        }
    },
    methods: {
        fixedScroll: function() {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > 50) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // PC
        initPcEvent: function() {
            // gnb
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].addEventListener("mouseenter", gnbLiOver);
                lis[i].addEventListener("mouseleave", gnbLiOut);
            }

            var gnb = document.querySelector(".gnb");
            gnb.addEventListener("mouseenter", gnbOver);
            gnb.addEventListener("mouseleave", gnbOut);
        },
        destroyPcEvent: function() {
            // gnb
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].removeEventListener("mouseenter", gnbLiOver);
                lis[i].removeEventListener("mouseleave", gnbLiOut);
            }

            var gnb = document.querySelector(".gnb");
            gnb.removeEventListener("mouseenter", gnbOver);
            gnb.removeEventListener("mouseleave", gnbOut);
        },
        // Mobile
        initMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector(".hamburger-btn");
            hamburgerBtn.addEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            gnbLinkToggle.forEach(function(el) {
                el.addEventListener("click", toggleAccordion);
            });
        },
        destroyMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector(".hamburger-btn");
            hamburgerBtn.removeEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            gnbLinkToggle.forEach(function(el) {
                el.removeEventListener("click", toggleAccordion);
            });
        },
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        if (this.isDesktop) {
            this.destroyPcEvent();
        }
        if (this.isMobile) {
            this.destroyMobileEvent();
        }
    },
    watch: {
        isDesktop: function() {
            if (this.isDesktop) {
                this.initPcEvent();
            } else {
                this.destroyPcEvent();
            }
        },
        isMobile: function() {
            if (this.isMobile) {
                this.initMobileEvent();
            } else {
                this.destroyMobileEvent();
            }
        },
    },
};

// PC
function gnbLiOver() {
    var headerBg = document.querySelector(".header__bg");
    var gnbSub = this.querySelector(".gnb__sub");

    this.classList.add("active");
    if (gnbSub == null) {
        headerBg.classList.add("none");
    }
}
function gnbLiOut() {
    var headerBg = document.querySelector(".header__bg");
    var gnbSub = this.querySelector(".gnb__sub");

    this.classList.remove("active");
    if (gnbSub == null) {
        headerBg.classList.remove("none");
    }
}
function gnbOver() {
    var header = document.querySelector(".header");
    header.classList.add("menu-active");
}
function gnbOut() {
    var header = document.querySelector(".header");
    header.classList.remove("menu-active");
}

// Mobile
function mobileMenuToggle() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");

    header.classList.toggle("mobile-menu-on");
    // HTML.classList.toggle('hidden');

    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function(el) {
            el.parentNode.classList.remove("active");
        });
    }
}
function toggleAccordion(el) {
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var targetLi = el.currentTarget.parentNode.classList;
    var target = el.currentTarget;

    el.preventDefault();
    if (targetLi.contains("active")) {
        targetLi.remove("active");
    } else {
        gnbLinkToggle.forEach(function(el) {
            el.parentNode.classList.remove("active");
        });
        targetLi.add("active");
    }
}
</script>
