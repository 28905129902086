<template>
    <!-- 퍼블확인용 -->
    <div class="board-item" @click="move">
        <v-card rounded class="mb-16">
            <div class="thumb">
                <div class="thumb__inner" :style="`background-image: url(${addiction.thumb})`"></div>
            </div>
        </v-card>
        <v-row class="row--x-small">
            <v-col>
                <v-chip color="primary-50" text-color="primary" class="mr-4 mr-lg-8">
                    {{ addiction?.code }}
                </v-chip>
            </v-col>
            <v-col cols="auto">
                <board-count :viewCount="addiction?.viewCount" :survey="true" />
                <!-- <board-count :viewCount="board?.viewCount" :commentCount="board?.commentCount" /> -->
            </v-col>
        </v-row>
        <p class="tit tit--sm font-weight-medium ellip mt-8 mt-lg-16">
            {{addiction?.subject}}
        </p>
        <div class="px-xl-8 mt-2 mt-xl-8">
            <board-tag :hashTags="addiction?.hashTags" />
        </div>
    </div>
</template>

<script>
import BoardTag from "@/components/client/board/board-tag.vue";
import BoardCount from "@/components/client/board/board-count.vue";

export default {
    props: {
        addiction: { type: Object },
    },
    components: {
        BoardTag,
        BoardCount,
    },
    methods: {
        move() {
            this.$router.push(`/content/test/${this.addiction._id}`)
        }
    },
};
</script>

<style scoped></style>
