import axios from "@/plugins/axios";
import questions from "./questions";
import jobs from "./jobs";
import addiction from "./addiction";

let url = "/api/v1/users";

export default {
    questions,
    jobs,
    addiction,
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    confirmNickname(data) {
        return axios.get(`${url}/nickname/confirm`, data).then((result) => result.data);
    },
    postBusinessRegistration(data, file) {
        var formData = new FormData();
        formData.append("businessRegistration", file);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/businessRegistration`, formData, { headers }).then((result) => result.data);
    },
    postLogo(data, logo) {
        var formData = new FormData();
        formData.append("logo", logo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/logo`, formData, { headers }).then((result) => result.data);
    },
    postPhoto(data, photo) {
        var formData = new FormData();
        formData.append("photo", photo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/photo`, formData, { headers }).then((result) => result.data);
    },
    repair(data){
        return axios.post(`${url}/repair`, data).then(result => result.data);
    },
    recovers(data) {
        return axios.get(`${url}/recovers`, data).then((result) => result.data)
    },
    recover(data) {
        return axios.get(`${url}/recover/${data?._id}`).then((result) => result.data)
    },
    recommendedRecovers(data) {
        return axios.get(`${url}/recommendedRecovers`, data).then((result) => result.data)
    }
};
