import axios from "@/plugins/axios";
import categories from "./categories";

let url = "/api/console/addiction";

export default {
    categories,
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`, data).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    statistics(data) {
        return axios.get(`${url}/${data._id}/statistics`, data).then((result) => result.data);
    },
    thumb: {
        post(_banner, file) {
            var formData = new FormData();
            formData.append("thumb", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return axios.post(`${url}/${_banner}/thumb`, formData, { headers }).then((result) => result.data);
        },
        delete(_banner, index) {
            return axios.delete(`${url}/${_banner}/thumb`).then((result) => result.data);
        },
    },
};
