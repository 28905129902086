var render = function render(){
  var _vm$commentCount;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('i', {
    staticClass: "icon icon-comment mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s((_vm$commentCount = _vm.commentCount) === null || _vm$commentCount === void 0 ? void 0 : _vm$commentCount.format()) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }