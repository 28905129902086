<template>
    <client-page class="app--main">
        <side-banner code="side">
            <main-banner class="main-section main-section--first" />

            <section class="main-section">
                <v-tabs color="grey-9" v-model="tab" hide-slider class="v-tabs--punch mb-16">
                    <v-tab>일간 인기글</v-tab>
                    <v-tab>주간 인기글</v-tab>
                    <v-tab>월간 인기글</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <!-- S: 일간 -->
                    <v-tab-item>
                        <div class="mb-20 mb-lg-28 d-flex justify-end">
                            <v-btn :to="`/community`" x-small rounded color="grey-6">
                                <i class="icon icon-plus mr-4"></i>
                                <span class="white--text">더 보기</span>
                            </v-btn>
                        </div>
                        <table class="board-list board-list--main">
                            <thead class="d-none d-lg-table-header">
                                <tr>
                                    <th style="width: 5.5%">순위</th>
                                    <th style="width: 76%" class="board-list__tit">제목</th>
                                    <th style="width: 9.5%">조회</th>
                                    <th style="width: 9%">댓글</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(board, index) in dayBoards" :key="board._id" @click="move2(board)">
                                    <td align="center" class="board-list__txt board-list__txt--first">
                                        {{ index + 1 }}
                                    </td>
                                    <td align="center" class="board-list__tit">
                                        <div class="board-list__tit grey-6--text">
                                            <board-tit small :image="board?.content?.includes('<img src')" :category="board?.category?.name" :tit="board?.subject"></board-tit>
                                            <v-row class="row--x-small mt-4">
                                                <v-col cols="auto">
                                                    <board-user-level />
                                                </v-col>
                                                <v-col cols="auto">
                                                    {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-view :viewCount="board?.viewCount" />
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-comment :commentCount="board?.commentCount" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-tab-item>
                    <!-- E: 일간 -->
                    <!-- S: 주간 -->
                    <v-tab-item>
                        <div class="mb-20 mb-lg-28 d-flex justify-end">
                            <v-btn :to="`/community`" x-small rounded color="grey-6">
                                <i class="icon icon-plus mr-4"></i>
                                <span class="white--text">더 보기</span>
                            </v-btn>
                        </div>
                        <table class="board-list board-list--main">
                            <thead class="d-none d-lg-table-header">
                                <tr>
                                    <th style="width: 5.5%">순위</th>
                                    <th style="width: 76%" class="board-list__tit">제목</th>
                                    <th style="width: 9.5%">조회</th>
                                    <th style="width: 9%">댓글</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(board, index) in weekBoards" :key="board._id" @click="move2(board)">
                                    <td align="center" class="board-list__txt board-list__txt--first">
                                        {{ index + 1 }}
                                    </td>
                                    <td align="center" class="board-list__tit">
                                        <div class="board-list__tit grey-6--text">
                                            <board-tit small :image="board?.content?.includes('<img src')" :category="board?.category?.name" :tit="board?.subject"></board-tit>
                                            <v-row class="row--x-small mt-4">
                                                <v-col cols="auto">
                                                    <board-user-level />
                                                </v-col>
                                                <v-col cols="auto">
                                                    {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-view :viewCount="board?.viewCount" />
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-comment :commentCount="board?.commentCount" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-tab-item>
                    <!-- E: 주간 -->
                    <!-- S: 월간 -->
                    <v-tab-item>
                        <div class="mb-20 mb-lg-28 d-flex justify-end">
                            <v-btn :to="`/community`" x-small rounded color="grey-6">
                                <i class="icon icon-plus mr-4"></i>
                                <span class="white--text">더 보기</span>
                            </v-btn>
                        </div>
                        <table class="board-list board-list--main">
                            <thead class="d-none d-lg-table-header">
                                <tr>
                                    <th style="width: 5.5%">순위</th>
                                    <th style="width: 76%" class="board-list__tit">제목</th>
                                    <th style="width: 9.5%">조회</th>
                                    <th style="width: 9%">댓글</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(board, index) in monthBoards" :key="board._id" @click="move2(board)">
                                    <td align="center" class="board-list__txt board-list__txt--first">
                                        {{ index + 1 }}
                                    </td>
                                    <td align="center" class="board-list__tit">
                                        <div class="board-list__tit grey-6--text">
                                            <board-tit small :image="board?.content?.includes('<img src')" :category="board?.category?.name" :tit="board?.subject"></board-tit>
                                            <v-row class="row--x-small mt-4">
                                                <v-col cols="auto">
                                                    <board-user-level />
                                                </v-col>
                                                <v-col cols="auto">
                                                    {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-view :viewCount="board?.viewCount" />
                                    </td>
                                    <td align="center" class="board-list__txt">
                                        <board-count-comment :commentCount="board?.commentCount" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-tab-item>
                    <!-- E: 월간 -->
                </v-tabs-items>
            </section>                

            <section class="main-section main-section--recover">
                <div class="mb-16">
                    <more-view-head to="/recover">      
                        <v-row align="center" class="row--small flex-nowrap">
                            <v-col cols="auto">
                                <h3 class="tit">추천 리커버</h3>
                            </v-col>    
                            <v-col>
                                <v-row class="row--x-small banner-slide__control flex-nowrap">
                                    <v-col cols="auto">
                                        <v-btn icon x-small class="slide-btn slide-btn--prev"><i class="icon icon-left-arrow icon-left-arrow--sm"></i></v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon x-small class="slide-btn slide-btn--next"><i class="icon icon-right-arrow icon-right-arrow--sm"></i></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>   
                        </v-row>
                    </more-view-head>
                </div>
                <div class="recover-slide">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <!-- S: 리커버 -->
                            <div v-for="recover in recovers" :key="recover._id" class="swiper-slide">
                                <recover-item :recover="{ ...recover.recover, _id: recover._id }"/>
                            </div>
                            <!-- E: 리커버 -->
                        </div>
                    </div>
                </div>
            </section>              

            <section class="main-section main-section--last main-section--contents">
                <div class="mb-16">
                    <more-view-head to="/content">      
                        <v-row align="center" class="row--small flex-nowrap">
                            <v-col cols="auto">
                                <h3 class="tit">인기 콘텐츠</h3>
                            </v-col>    
                            <v-col>
                                <v-row class="row--x-small banner-slide__control flex-nowrap">
                                    <v-col cols="auto">
                                        <v-btn icon x-small class="slide-btn slide-btn--prev"><i class="icon icon-left-arrow icon-left-arrow--sm"></i></v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon x-small class="slide-btn slide-btn--next"><i class="icon icon-right-arrow icon-right-arrow--sm"></i></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>   
                        </v-row>
                    </more-view-head>
                </div>
                <div class="contents-slide">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <!-- S: 리커버 -->
                            <router-link v-for="board in recommnedBoards" :key="board._id" :to="'/content/solution/'+board._id" class="swiper-slide">
                                <board-item-primary :board="board" />
                            </router-link>
                            <!-- E: 리커버 -->
                        </div>
                    </div>
                </div>
            </section>   
        </side-banner>
        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainBanner from "@/components/client/main/main-banner.vue";
import SideBanner from "@/components/client/inc/side-banner.vue";
import MoreViewHead from "@/components/dumb/more-view-head.vue";
import AddictionItemPrimary from "@/components/client/addiction/addiction-item--primary.vue";
import BoardCount from "@/components/client/board/board-count.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";
import BoardCountComment from "@/components/client/board/board-count--comment.vue";
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardUserLevel from "@/components/client/board/board-user-level.vue";
import RecoverItem from "@/components/client/recover/recover-item.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainBanner,
        SideBanner,
        MoreViewHead,
        AddictionItemPrimary,
        BoardCount,
        BoardCountView,
        BoardCountComment,
        BoardItemPrimary,
        BoardTit,
        BoardUserLevel,
        RecoverItem,
    },
    props: {
        small: { type: Boolean, default: false },
        justifyEnd: { type: Boolean, default: false },
    },
    data() {
        return {
            tab: null,
            sideBanner: {},

            informations: [],
            informationFilter: {
                onDisplay: true,
                approvalStatus: "승인",
                code: ["topic", "column"],
            },

            recovers: null,

            dayBoards: [],
            dayBoardsFilter: {
                approvalStatus: "승인",
                code: "community",
                sortKey: "viewCount",
                createdAt: [this.$dayjs().startOf("day").format("YYYY-MM-DD"), this.$dayjs().endOf("day").format("YYYY-MM-DD")],
            },

            weekBoards: [],
            weekBoardsFilter: {
                approvalStatus: "승인",
                code: "community",
                sortKey: "viewCount",
                createdAt: [this.$dayjs().startOf("week").format("YYYY-MM-DD"), this.$dayjs().endOf("week").format("YYYY-MM-DD")],
            },

            monthBoards: [],
            monthBoardsFilter: {
                approvalStatus: "승인",
                code: "community",
                sortKey: "viewCount",
                createdAt: [this.$dayjs().startOf("month").format("YYYY-MM-DD"), this.$dayjs().endOf("month").format("YYYY-MM-DD")],
            },

            recommnedBoards: [],
            recommnedBoardsFilter: {
                approvalStatus: "승인",
                code: "content",
                onDisplay: true,
            },
        };
    },
    async mounted() {
        await this.init();

        (adsbygoogle = window.adsbygoogle || []).push({});
    },
    methods: {
        async init() {
            await this.getRecovers();
            await this.getBanner();
            await this.getBoards();
            this.$nextTick(() => {
                this.swiper = new Swiper(".recover-slide .swiper-container", {
                    spaceBetween: 10,
                    slidesPerView: 2,
                    speed: 500,
                    loop: true,
                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: ".main-section--recover .slide-btn--next",
                        prevEl: ".main-section--recover .slide-btn--prev",
                    },
                    breakpoints: {
                        768:{
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1024:{
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    },
                });
                this.swiperContents = new Swiper(".contents-slide .swiper-container", {
                    spaceBetween: 10,
                    slidesPerView: 2,
                    speed: 500,
                    loop: true,
                    // autoplay: {
                    //     delay: 10000,
                    //     disableOnInteraction: false,
                    // },
                    navigation: {
                        nextEl: ".main-section--contents .slide-btn--next",
                        prevEl: ".main-section--contents .slide-btn--prev",
                    },
                    breakpoints: {
                        768:{
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1024:{
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                    },
                });
            });
        },
        async getRecovers() {
            let { recovers } = await api.v1.users.recommendedRecovers()

            this.recovers = recovers
        },
        async getBanner() {
            let { banner: sideBanner } = await api.v1.banners.get({ code: "side", params: { levelCode: this.levelCode } });

            this.sideBanner = sideBanner;
        },
        async getBoards() {
            let [{ boards: recommnedBoards }, { boards: dayBoards }, { boards: weekBoards }, { boards: monthBoards }] = await Promise.all([
                api.v1.boards.gets({ headers: { limit: 3 }, params: { ...this.recommnedBoardsFilter } }), 
                api.v1.boards.gets({ headers: { limit: 5 }, params: { ...this.dayBoardsFilter } }), 
                api.v1.boards.gets({ headers: { limit: 5 }, params: { ...this.weekBoardsFilter } }), 
                api.v1.boards.gets({ headers: { limit: 5 }, params: { ...this.monthBoardsFilter } })]);
            this.recommnedBoards = recommnedBoards;
            this.dayBoards = dayBoards;
            this.weekBoards = weekBoards;
            this.monthBoards = monthBoards;
        },
        move(board) {
            this.$router.push(`/content/solution/${board._id}`);
        },
        move2(board) {
            this.$router.push(`/community/board/${board._id}`);
        },
        move3(board) {
            switch (board.code) {
                case "column":
                    this.$router.push(`/content/column/${board._id}`);
                    break;
                case "topic":
                    this.$router.push(`/information/issue/${board._id}`);
                    break;

                default:
                    break;
            }
        },
    },
    computed: {
        levelCode() {
            return this.$store.state.levelCode;
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-btn{
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: rgba(0, 0, 0, .3);
    }
}
.recover-slide{
    .swiper-slide{
        max-width: calc((100% - 10px) / 2);
    }
}
.contents-slide{
    .swiper-slide{
        max-width: calc((100% - 10px) / 2);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .recover-slide{
        .swiper-slide{
            max-width: calc((100% - 20px) / 3);
        }
    }
    .contents-slide{
        .swiper-slide{
            max-width: calc((100% - 20px) / 3);
        }
    }
}
@media (min-width:1024px){
    .recover-slide{
        .swiper-slide{
            max-width: calc((100% - 80px) / 5);
        }
    }
    .contents-slide{
        .swiper-slide{
            max-width: calc((100% - 40px) / 3);
        }
    }
}
@media (min-width:1200px){
}

</style>
