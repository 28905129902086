<template>
    <v-row class="board-item__tit row--x-small" :class="{ 'board-item__tit--sm': small }">
        <v-col cols="auto">
            <v-chip color="primary-50" text-color="primary" class="mr-4 mr-lg-8">
                {{ category }}
            </v-chip>
        </v-col>
        <v-col>
            <v-row align="center" class="row--x-small">
                <v-col cols="12" md="auto" class="mw-100">
                    <p class="ellip tit tit--sm grey-1--text" :class="!small ? 'font-weight-medium' : ''">{{ tit }}</p>
                </v-col>
                <v-col v-if="image" class="d-flex justify-end justify-md-start">
                    <i class="icon icon-image"></i>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        category: { type: String, default: null },
        tit: { type: String, default: "" },
        small: { type: Boolean, default: false },
        image: { type: Boolean, default: false },
    },
};
</script>
