var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "end",
      "justify-xl": "start"
    }
  }, [_c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.accessToken ? _c('router-link', {
    staticClass: "user-menu__link",
    attrs: {
      "to": "/mypage/community"
    }
  }, [_c('span', [_vm._v("마이페이지")])]) : _c('router-link', {
    staticClass: "user-menu__link",
    attrs: {
      "to": "/join"
    }
  }, [_c('span', [_vm._v("회원가입")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "user-menu__line"
  })]), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }