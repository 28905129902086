import axios from "@/plugins/axios";
import levelups from "./levelups"
import statistics from "./statistics";
import jobs from "./jobs";
import addiction from "./addiction";
import hashtag from "./hashtag";

let url = "/api/console/users";

export default {
    levelups,
    statistics,
    jobs,
    addiction,
    hashtag,
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
