var render = function render(){
  var _vm$user5, _vm$user6, _vm$user7, _vm$user8, _vm$user9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.noneActive ? _c('v-menu', {
    attrs: {
      "bottom": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$user, _vm$user2, _vm$user3, _vm$user4;
        var on = _ref.on;
        return [_c('button', _vm._g({
          staticClass: "d-block",
          class: {
            'user-button--lg': _vm.large
          }
        }, on), [_c('v-row', {
          staticClass: "ma-n2",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.levelCode) === 'normal' ? _c('i', {
          staticClass: "icon icon-user-level1"
        }) : _vm._e(), ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.levelCode) === 'experience' ? _c('i', {
          staticClass: "icon icon-user-level2"
        }) : _vm._e(), ((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.levelCode) === 'expert' ? _c('i', {
          staticClass: "icon icon-user-level3"
        }) : _vm._e()]), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('p', {
          class: _vm.textStyle
        }, [_vm._v(_vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.nickname))])])], 1)], 1)];
      }
    }], null, false, 3901920763)
  }, [_c('v-btn', {
    staticClass: "id-btn h-lg-40px min-w-lg-120px",
    attrs: {
      "to": `/posting/${(_vm$user5 = _vm.user) === null || _vm$user5 === void 0 ? void 0 : _vm$user5._id}`,
      "small": "",
      "color": "white"
    }
  }, [_c('span', {
    staticClass: "grey-1--text font-weight-regular"
  }, [_vm._v(" 작성 글 보기 ")])])], 1) : _c('v-row', {
    staticClass: "ma-n2",
    class: {
      'user-button--lg': _vm.large
    },
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "auto"
    }
  }, [((_vm$user6 = _vm.user) === null || _vm$user6 === void 0 ? void 0 : _vm$user6.levelCode) === 'normal' ? _c('i', {
    staticClass: "icon icon-user-level1"
  }) : _vm._e(), ((_vm$user7 = _vm.user) === null || _vm$user7 === void 0 ? void 0 : _vm$user7.levelCode) === '유경험자' ? _c('i', {
    staticClass: "icon icon-user-level2"
  }) : _vm._e(), ((_vm$user8 = _vm.user) === null || _vm$user8 === void 0 ? void 0 : _vm$user8.levelCode) === '전문가' ? _c('i', {
    staticClass: "icon icon-user-level3"
  }) : _vm._e()]), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    class: _vm.textStyle
  }, [_vm._v(_vm._s((_vm$user9 = _vm.user) === null || _vm$user9 === void 0 ? void 0 : _vm$user9.nickname))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }