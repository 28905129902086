var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('side-banner', {
    attrs: {
      "code": "side"
    }
  }, [_c('main-banner', {
    staticClass: "main-section main-section--first"
  }), _c('section', {
    staticClass: "main-section"
  }, [_c('v-tabs', {
    staticClass: "v-tabs--punch mb-16",
    attrs: {
      "color": "grey-9",
      "hide-slider": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("일간 인기글")]), _c('v-tab', [_vm._v("주간 인기글")]), _c('v-tab', [_vm._v("월간 인기글")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('div', {
    staticClass: "mb-20 mb-lg-28 d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "to": `/community`,
      "x-small": "",
      "rounded": "",
      "color": "grey-6"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus mr-4"
  }), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("더 보기")])])], 1), _c('table', {
    staticClass: "board-list board-list--main"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "5.5%"
    }
  }, [_vm._v("순위")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "76%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "9.5%"
    }
  }, [_vm._v("조회")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("댓글")])])]), _c('tbody', _vm._l(_vm.dayBoards, function (board, index) {
    var _board$content, _board$category;
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.move2(board);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit grey-6--text"
    }, [_c('board-tit', {
      attrs: {
        "small": "",
        "image": board === null || board === void 0 ? void 0 : (_board$content = board.content) === null || _board$content === void 0 ? void 0 : _board$content.includes('<img src'),
        "category": board === null || board === void 0 ? void 0 : (_board$category = board.category) === null || _board$category === void 0 ? void 0 : _board$category.name,
        "tit": board === null || board === void 0 ? void 0 : board.subject
      }
    }), _c('v-row', {
      staticClass: "row--x-small mt-4"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level')], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])], 1)], 1)]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-view', {
      attrs: {
        "viewCount": board === null || board === void 0 ? void 0 : board.viewCount
      }
    })], 1), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-comment', {
      attrs: {
        "commentCount": board === null || board === void 0 ? void 0 : board.commentCount
      }
    })], 1)]);
  }), 0)])]), _c('v-tab-item', [_c('div', {
    staticClass: "mb-20 mb-lg-28 d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "to": `/community`,
      "x-small": "",
      "rounded": "",
      "color": "grey-6"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus mr-4"
  }), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("더 보기")])])], 1), _c('table', {
    staticClass: "board-list board-list--main"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "5.5%"
    }
  }, [_vm._v("순위")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "76%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "9.5%"
    }
  }, [_vm._v("조회")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("댓글")])])]), _c('tbody', _vm._l(_vm.weekBoards, function (board, index) {
    var _board$content2, _board$category2;
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.move2(board);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit grey-6--text"
    }, [_c('board-tit', {
      attrs: {
        "small": "",
        "image": board === null || board === void 0 ? void 0 : (_board$content2 = board.content) === null || _board$content2 === void 0 ? void 0 : _board$content2.includes('<img src'),
        "category": board === null || board === void 0 ? void 0 : (_board$category2 = board.category) === null || _board$category2 === void 0 ? void 0 : _board$category2.name,
        "tit": board === null || board === void 0 ? void 0 : board.subject
      }
    }), _c('v-row', {
      staticClass: "row--x-small mt-4"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level')], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])], 1)], 1)]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-view', {
      attrs: {
        "viewCount": board === null || board === void 0 ? void 0 : board.viewCount
      }
    })], 1), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-comment', {
      attrs: {
        "commentCount": board === null || board === void 0 ? void 0 : board.commentCount
      }
    })], 1)]);
  }), 0)])]), _c('v-tab-item', [_c('div', {
    staticClass: "mb-20 mb-lg-28 d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "to": `/community`,
      "x-small": "",
      "rounded": "",
      "color": "grey-6"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus mr-4"
  }), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("더 보기")])])], 1), _c('table', {
    staticClass: "board-list board-list--main"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "5.5%"
    }
  }, [_vm._v("순위")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "76%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "9.5%"
    }
  }, [_vm._v("조회")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("댓글")])])]), _c('tbody', _vm._l(_vm.monthBoards, function (board, index) {
    var _board$content3, _board$category3;
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.move2(board);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit grey-6--text"
    }, [_c('board-tit', {
      attrs: {
        "small": "",
        "image": board === null || board === void 0 ? void 0 : (_board$content3 = board.content) === null || _board$content3 === void 0 ? void 0 : _board$content3.includes('<img src'),
        "category": board === null || board === void 0 ? void 0 : (_board$category3 = board.category) === null || _board$category3 === void 0 ? void 0 : _board$category3.name,
        "tit": board === null || board === void 0 ? void 0 : board.subject
      }
    }), _c('v-row', {
      staticClass: "row--x-small mt-4"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('board-user-level')], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])], 1)], 1)]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-view', {
      attrs: {
        "viewCount": board === null || board === void 0 ? void 0 : board.viewCount
      }
    })], 1), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('board-count-comment', {
      attrs: {
        "commentCount": board === null || board === void 0 ? void 0 : board.commentCount
      }
    })], 1)]);
  }), 0)])])], 1)], 1), _c('section', {
    staticClass: "main-section main-section--recover"
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/recover"
    }
  }, [_c('v-row', {
    staticClass: "row--small flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("추천 리커버")])]), _c('v-col', [_c('v-row', {
    staticClass: "row--x-small banner-slide__control flex-nowrap"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--prev",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow icon-left-arrow--sm"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--next",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow icon-right-arrow--sm"
  })])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "recover-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.recovers, function (recover) {
    return _c('div', {
      key: recover._id,
      staticClass: "swiper-slide"
    }, [_c('recover-item', {
      attrs: {
        "recover": Object.assign({}, recover.recover, {
          _id: recover._id
        })
      }
    })], 1);
  }), 0)])])]), _c('section', {
    staticClass: "main-section main-section--last main-section--contents"
  }, [_c('div', {
    staticClass: "mb-16"
  }, [_c('more-view-head', {
    attrs: {
      "to": "/content"
    }
  }, [_c('v-row', {
    staticClass: "row--small flex-nowrap",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("인기 콘텐츠")])]), _c('v-col', [_c('v-row', {
    staticClass: "row--x-small banner-slide__control flex-nowrap"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--prev",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow icon-left-arrow--sm"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "slide-btn slide-btn--next",
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-right-arrow icon-right-arrow--sm"
  })])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "contents-slide"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.recommnedBoards, function (board) {
    return _c('router-link', {
      key: board._id,
      staticClass: "swiper-slide",
      attrs: {
        "to": '/content/solution/' + board._id
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": board
      }
    })], 1);
  }), 1)])])])], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }