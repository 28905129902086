<template>
    <v-row no-gutters align="center">
        <v-col cols="" v-if="this.$slots['default']">
            <slot/>
        </v-col>
        <v-col cols="auto" class="d-flex justify-end">
            <v-btn :to="to" x-small rounded color="grey-6">
                <i class="icon icon-plus mr-4"></i>
                <span class="white--text">더 보기</span>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

export default{
    props : {
        to: {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
	},
	methods : {
	},
}
</script>

<style scoped>

</style>