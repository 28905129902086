<template>
    <div class="side-banner">
        <v-container>
            <div class="side-banner__inner">
                <div class="side-banner__left">
                    <slot />
                </div>
                <div class="side-banner__right" v-if="sideBanner">
                    <!-- 모바일일때 해당 영역 안나옴. 구글 애드센스 변경시 확인. -->
                    <a :href="sideBanner?.url" target="_blank">
                        <v-img contain class="w-100" :src="sideBanner?.image" :aspect-ratio="250/700"></v-img>
                    </a>

                    <!-- 구글 애드센스 -->
                    <!-- 리커버스 우측 광고 -->
                    <!-- 임시로 display: none 해놓음. 사용시 display: block으로 변경. -->
                    <ins class="adsbygoogle"
                    style="display:none;"
                    data-ad-client="ca-pub-1978848462814059"
                    data-ad-slot="4701301642"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: {
        code: { type: String }
    },
    data() {
        return {
            sideBanner: {},
        }
    },
    async mounted() {
        await this.init();
        (adsbygoogle = window.adsbygoogle || []).push({});
    },
    methods: {
        async init() {
            await this.getBanner();
        },
        async getBanner() {
            let { banner: sideBanner } = await api.v1.banners.get({ code: this.code, params: { levelCode: this.levelCode } });

            this.sideBanner = sideBanner;
        },
    },
    computed: {
        levelCode() {
            return this.$store.state.levelCode;
        },
    },
};
</script>

<style lang="scss" scoped>
.side-banner__right{
    display: none;
    max-width: 290px;
    margin: 0 auto 20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .side-banner__inner{
        display: flex;
    }
    .side-banner__left{
        width: calc(100% - 290px);
    }
    .side-banner__right{
        display: block;
        width: 290px;
        padding: 60px 0 60px 40px;
        margin:auto;
    }
}
</style>
