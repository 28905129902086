<template>
    <div class="d-flex align-center" :class="{ 'justify-end': justifyEnd, 'justify-center': !justifyEnd }">
        <i class="icon icon-view mr-4 mr-lg-8"></i>
        <p class="page-text--sm grey-9--text">
            {{ viewCount?.format() }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        justifyEnd: { type: Boolean, default: false },
        viewCount: { type: Number },
    },
    data() {
        return {};
    },
};
</script>
