var render = function render(){
  var _vm$buttomBanner, _vm$buttomBanner2, _vm$buttomBanner3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$buttomBanner = _vm.buttomBanner) !== null && _vm$buttomBanner !== void 0 && _vm$buttomBanner.image ? _c('div', {
    staticClass: "mb-60 mb-lg-100"
  }, [_c('v-container', [_c('a', {
    attrs: {
      "href": (_vm$buttomBanner2 = _vm.buttomBanner) === null || _vm$buttomBanner2 === void 0 ? void 0 : _vm$buttomBanner2.url,
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": (_vm$buttomBanner3 = _vm.buttomBanner) === null || _vm$buttomBanner3 === void 0 ? void 0 : _vm$buttomBanner3.image,
      "aspect-ratio": 1600 / 230
    }
  })], 1), _c('ins', {
    staticClass: "adsbygoogle",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-ad-client": "ca-pub-1978848462814059",
      "data-ad-slot": "5034405767",
      "data-ad-format": "auto",
      "data-full-width-responsive": "true"
    }
  })])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }