var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [this.$slots['default'] ? _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_vm._t("default")], 2) : _vm._e(), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": _vm.to,
      "x-small": "",
      "rounded": "",
      "color": "grey-6"
    }
  }, [_c('i', {
    staticClass: "icon icon-plus mr-4"
  }), _c('span', {
    staticClass: "white--text"
  }, [_vm._v("더 보기")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }