var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('main-header'), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2)]), _c('main-footer-ad'), _c('main-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }