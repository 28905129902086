var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "board-item__tit row--x-small",
    class: {
      'board-item__tit--sm': _vm.small
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "mr-4 mr-lg-8",
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.category) + " ")])], 1), _c('v-col', [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mw-100",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "ellip tit tit--sm grey-1--text",
    class: !_vm.small ? 'font-weight-medium' : ''
  }, [_vm._v(_vm._s(_vm.tit))])]), _vm.image ? _c('v-col', {
    staticClass: "d-flex justify-end justify-md-start"
  }, [_c('i', {
    staticClass: "icon icon-image"
  })]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }