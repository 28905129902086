<template>
    <v-row class="row--x-small" align="center" justify="end" justify-xl="start">
        <v-col cols="auto" class="line-height-1">
            <router-link v-if="accessToken" to="/mypage/community" class="user-menu__link">
                <!-- <i class="icon icon-person mr-4 mr-lg-8"></i> -->
                <span>마이페이지</span>
            </router-link>
            <router-link v-else to="/join" class="user-menu__link">
                <span>회원가입</span>
            </router-link>
        </v-col>
        <v-col cols="auto">
            <span class="user-menu__line"></span>
        </v-col>
        <v-col cols="auto" class="line-height-1">
            <gnb-login-button></gnb-login-button>
        </v-col>
    </v-row>
</template>

<script>
import gnbLoginButton from "@/components/client/login/gnb-login-button.vue";

export default {
    components: {
        gnbLoginButton,
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style scoped></style>
