var render = function render(){
  var _vm$addiction, _vm$addiction2, _vm$addiction3, _vm$addiction4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-item",
    on: {
      "click": _vm.move
    }
  }, [_c('v-card', {
    staticClass: "mb-16",
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "thumb"
  }, [_c('div', {
    staticClass: "thumb__inner",
    style: `background-image: url(${_vm.addiction.thumb})`
  })])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('v-chip', {
    staticClass: "mr-4 mr-lg-8",
    attrs: {
      "color": "primary-50",
      "text-color": "primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$addiction = _vm.addiction) === null || _vm$addiction === void 0 ? void 0 : _vm$addiction.code) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-count', {
    attrs: {
      "viewCount": (_vm$addiction2 = _vm.addiction) === null || _vm$addiction2 === void 0 ? void 0 : _vm$addiction2.viewCount,
      "survey": true
    }
  })], 1)], 1), _c('p', {
    staticClass: "tit tit--sm font-weight-medium ellip mt-8 mt-lg-16"
  }, [_vm._v(" " + _vm._s((_vm$addiction3 = _vm.addiction) === null || _vm$addiction3 === void 0 ? void 0 : _vm$addiction3.subject) + " ")]), _c('div', {
    staticClass: "px-xl-8 mt-2 mt-xl-8"
  }, [_c('board-tag', {
    attrs: {
      "hashTags": (_vm$addiction4 = _vm.addiction) === null || _vm$addiction4 === void 0 ? void 0 : _vm$addiction4.hashTags
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }