var render = function render(){
  var _vm$hashTags;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small"
  }, _vm._l((_vm$hashTags = _vm.hashTags) === null || _vm$hashTags === void 0 ? void 0 : _vm$hashTags.split(', '), function (hashTag, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text primary--text"
    }, [_vm._v(" #"), _c('span', [_vm._v(_vm._s(hashTag))])])]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }