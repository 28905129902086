<template>
    <div class="board-item">
        <v-card rounded class="mb-16">
            <div class="thumb">
                <div class="thumb__inner" :style="`background-image: url(${board?.thumb})`"></div>
                <div v-if="hot" class="icon-hot">HOT</div>
            </div>
        </v-card>
        <v-row class="row--x-small">
            <v-col>
                <!-- <board-tit :category="board?.category.name" :tit="board?.subject" /> -->
                <v-chip color="primary-50" text-color="primary" class="mr-4 mr-lg-8">
                    {{ board?.category.name }}
                </v-chip>
            </v-col>
            <v-col cols="auto">
                <board-count :viewCount="board?.viewCount" :commentCount="board?.commentCount" />
            </v-col>
        </v-row>
        <p class="tit tit--sm font-weight-medium ellip mt-8 mt-lg-16">{{board?.subject}}</p>
        <div v-if="tag" class="px-xl-8 mt-2 mt-xl-8">
            <board-tag :hashTags="board?.hashTags" />
        </div>
    </div>
</template>

<script>
// import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCount from "@/components/client/board/board-count.vue";
import BoardTag from "@/components/client/board/board-tag.vue";

export default {
    props: {
        addiction: { type: Object },
        tag: { type: Boolean },
        hot: { type: Boolean },
        board: { type: Object, default: {} },
    },
    components: {
        // BoardTit,
        BoardCount,
        BoardTag,
    },
    data() {
        return {
            
        };
    },
    methods: {
    },
};
</script>

<style lang="scss" scoped>
.icon-hot {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #fa4205;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    top: 10px;
    left: 10px;
    z-index: 5;
    border-radius: 50%;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .icon-hot {
        width: 55px;
        height: 55px;
        font-size: 1.6rem;
        top: 16px;
        left: 16px;
    }
}
@media (min-width: 1200px) {
}
</style>
