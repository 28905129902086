var render = function render(){
  var _vm$viewCount;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center",
    class: {
      'justify-end': _vm.justifyEnd,
      'justify-center': !_vm.justifyEnd
    }
  }, [_c('i', {
    staticClass: "icon icon-view mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s((_vm$viewCount = _vm.viewCount) === null || _vm$viewCount === void 0 ? void 0 : _vm$viewCount.format()) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }