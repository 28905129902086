<template>
    <div class="d-flex justify-center align-center">
        <i class="icon icon-comment mr-4 mr-lg-8"></i>
        <p class="page-text--sm grey-9--text">
            {{ commentCount?.format() }}
        </p>
    </div>
</template>

<script>

export default {
    props: {
        commentCount: { type: Number, default: null }
    },
    data() {
        return {
        };
    },
};

</script>
