<template>
    <footer class="footer">
        <div class="footer__top py-16">
            <v-container class="container--sm">
                <v-row class="page-text--sm grey-9--text">
                    <v-col cols="auto">
                        <router-link to="/notice" class="footer__link">공지사항</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/event" class="footer__link">이벤트</router-link>
                    </v-col>
                    <v-col v-if="accessToken" cols="auto">
                        <router-link to="/mypage/inquiry" class="footer__link">문의사항</router-link>
                    </v-col>
                    <v-col v-else cols="auto">
                        <router-link to="/inquiry" class="footer__link">문의사항</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/service" class="footer__link">서비스 소개</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/terms" class="footer__link">이용약관</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/privacy-policy" class="footer__link">개인정보처리방침</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/report" class="footer__link">신고가이드</router-link>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="footer__bottom py-40">
            <v-container class="container--sm">
                <v-row class="row--large" align-md="center">
                    <v-col cols="12" md="auto" class="mb-6 mb-md-0">
                        <v-img src="/images/ci.svg" contain max-width="164"></v-img>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <p class="page-text--sm line-height-15 grey-9--text">
                            회사명 :  이겨내컴퍼니 주식회사<span class="text-center d-none d-md-inline-block" style="width: 40px">|</span><br class="d-block d-md-none">
                            대표자명 : 차유람<span class="text-center d-none d-md-inline-block" style="width: 40px">|</span><br class="d-block d-md-none">
                            대표번호 : 032-217-1267<span class="text-center d-none d-md-inline-block" style="width: 40px">|</span><br class="d-block d-md-none">
                            사업등록번호 : 446-88-01241 <br>
                            주소 : 인천광역시 부평구 부평대로 293, 9층 908호(청천동, 인천콘텐츠기업지원센터)
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <u-scroll-top></u-scroll-top>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";

export default {
    components: {
        UScrollTop,
    },
    data() {
        return {
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style scoped>
.footer {
    font-size: 1.4rem;
}
.footer__top{
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .footer__link:hover{
        color: var(--v-primary-base);
    }
}
@media (min-width:1200px){
}
</style>
