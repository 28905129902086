import axios from "@/plugins/axios";

let url = "/api/console/boards/comments/configs";

export default {
    get(data){
        return axios.get(`${url}`).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
}