import faqs from "./faqs";
import notifications from "./notifications";
import questions from "./questions";
import inquirys from "./inquirys";

export default {
    faqs,
    notifications,
    questions,
    inquirys
};
