import axios from "@/plugins/axios";

let url = "/api/v1/center/inquirys";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postFile(data, file) {
        var formData = new FormData();
        formData.append("file", file);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/file`, formData, { headers }).then(result => result.data);
    }
}
